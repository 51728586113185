export const States = {
	"admin": {
		"relatiiTabeleEdit": "RelatiiTabeleEdit",
		"resursa": "admin/Resursa",
		"resursaControl": "admin/Resursa/control",
		"resursaSetIcon": "admin/Resursa/setIcon",
		"clientStartUp": "client/StartUp",
		"resurseAdaugateRecent": "admin/ResurseAdaugateRecent",
		"dezvoltareActualizare": "admin/dezvoltare/actualizare",
		"dezvoltareBranch": "admin/dezvoltare/branch",
		"ngtest": "ng/admin/ngtest",
		"home": "home",
		"commonSearch": "common/Search",
		"clientLogin": "client/Login",
		"cont": "admin/Cont",
		"stop": "admin/Stop",
		"mentenanta": "admin/Mentenanta/",
		"user": "admin/User",
		"asociereUserPersoana": "AsociereUserPersoana",
		"userAccesLaDate": "admin/userAccesLaDate",
		"userUserorganizareinstitutionala": "admin/user/userorganizareinstitutionala",
		"userDetaliiDrepturiAcces": "admin/User/DetaliiDrepturiAcces",
		"userExtern": "admin/UserExtern",
		"backupBackupFolder": "admin/backup/backupFolder",
		"backupServere": "admin/backup/servere",
		"backupBackupPlan": "admin/backup/backupPlan",
		"backupBackupFile": "admin/backup/backupFile",
		"backupLog": "admin/backup/log",
		"backupConfigurations": "admin/backup/configurations",
		"backupStartup": "admin/backup/startup",
		"backupBackupsigma": "admin/backup/backupsigma/",
		"ghidSigma": "ghid/sigma",
		"ghidAdministrare_sistem": "ghid/administrare_sistem",
		"ghidAdministrare_institutie": "ghid/administrare_institutie",
		"ghidPersoane": "ghid/persoane",
		"ghidAdrese": "ghid/adrese",
		"ghidDocumente": "ghid/documente",
		"ghidBuget": "ghid/buget",
		"ghidContab": "ghid/contab",
		"ghidStocuri_si_imobilizari": "ghid/stocuri_si_imobilizari",
		"ghidRaportari_financiare": "ghid/raportari_financiare",
		"ghidImpotax": "ghid/impotax",
		"ghidIncasari_si_plati": "ghid/incasari_si_plati",
		"ghidAchizitii": "ghid/achizitii",
		"ghidClienti": "ghid/clienti",
		"ghidResurse_umane": "ghid/resurse_umane",
		"ghidSalarii": "ghid/salarii",
		"ghidAsistenta_sociala": "ghid/asistenta_sociala",
		"ghidAgroregis": "ghid/agroregis",
		"ghidContracte": "ghid/contracte",
		"ghidNomenclatoare_generale": "ghid/nomenclatoare_generale",
		"ghidDeploy": "ghid/deploy",
		"ghidConversii_date": "ghid/conversii_date",
		"ghidInvatamant": "ghid/invatamant",
		"ghidCantina": "ghid/cantina",
		"ghidInvestitii": "ghid/investitii",
		"ghidPachetfincon": "ghid/pachetfincon",
		"ghidPachetfinconconfig": "ghid/pachetfinconconfig",
		"ghidPachetfinconflux": "ghid/pachetfinconflux",
		"ghidFluxincasarifaradebitavansat": "ghid/fluxincasarifaradebitavansat",
		"ghidStatplatapers": "ghid/statplatapers",
		"ghidStatplatacolab": "ghid/statplatacolab",
		"ghidStatplataalte": "ghid/statplataalte",
		"ghidFluxdeplasari": "ghid/fluxdeplasari",
		"ghidFluxavansdecontare": "ghid/fluxavansdecontare",
		"rapoarte": "admin/Rapoarte",
		"detaliiRapoarte": "admin/DetaliiRapoarte",
		"situatieRapoarte": "admin/SituatieRapoarte",
		"semnaturiSemnaturi": "admin/semnaturi/semnaturi",
		"semnaturirapoarte": "admin/semnaturirapoarte",
		"antet": "admin/antet",
		"setare": "admin/Setare",
		"setareEdit": "admin/Setare/edit",
		"setareUser": "admin/Setare/user/",
		"testtypeaheadTesttypeahead": "admin/testtypeahead/testtypeahead/",
		"relatiiTabele": "RelatiiTabele",
		"importAdrese": "admin/import/adrese",
		"intretinereIntretineredb": "admin/intretinere/intretineredb",
		"reportingConfig": "admin/reporting/config/",
		"debug": "admin/Debug",
		"testdataserviceTestdataservice": "admin/testdataservice/testdataservice",
		"systemInfo": "admin/SystemInfo/",
		"testdataserviceDataservicelog": "admin/testdataservice/dataservicelog/",
		"nomenclatoareActualizareNomenclatoare": "admin/nomenclatoare/actualizareNomenclatoare/",
		"relatiiTabeleUnitate": "RelatiiTabeleUnitate",
		"problemaunitate": "problemaunitate",
		"startUp": "startUp"
	},
	"administrareinstitutie": {
		"administrareUnitati": "administrare/Unitati/",
		"administrareUnitatiNew": "administrare/Unitati/new",
		"administrareOrdonatorCrediteNew": "administrare/OrdonatorCredite/new",
		"administrareOrganizareInstitutionala": "administrare/OrganizareInstitutionala",
		"administrareOrganizareInstitutionalaNew": "administrare/OrganizareInstitutionala/new",
		"administrareOrganigrama": "administrare/Organigrama",
		"administrareOrganigramaPostOrganigrama": "administrare/Organigrama/postOrganigrama",
		"administrareOrganigramaPostOrganigramaContract": "administrare/Organigrama/postOrganigramaContract",
		"administrareOrganigramaOrganigramaSetari": "administrare/organigrama/OrganigramaSetari",
		"tipvenitTipurivenituri": "administrareinstitutie/tipvenit/tipurivenituri",
		"tipvenitTipurivenituriAsocieri": "administrareinstitutie/tipvenit/tipurivenituri/asocieri",
		"tipvenitTipurivenituriEdit": "administrareinstitutie/tipvenit/tipurivenituri/edit",
		"contabAsociereAECapitolArticol": "Contab/AsociereAECapitolArticol",
		"contabAsociereAECapitolArticolEdit": "Contab/AsociereAECapitolArticolEdit",
		"administrareAsociereClasificatieMultipla": "administrare/asociereClasificatieMultipla",
		"administrareAdaugareAsociereClasificatieMultipla": "administrare/adaugareAsociereClasificatieMultipla",
		"administrareAdaugareDetaliuClasificatieMultipla": "administrare/adaugareDetaliuClasificatieMultipla",
		"administrareEditareProcenteDetalii": "administrare/editareProcenteDetalii"
	},
	"persoane": {
		"nomenclatorPersoane": "persoane/NomenclatorPersoane",
		"persoanaPfa": "persoana/pfa",
		"persoanaFiz": "persoana/fiz",
		"persoanaBanca": "persoana/Banca",
		"persoanaJur": "persoana/jur",
		"persoanaunitate": "persoane/persoanaunitate",
		"persoanaunitatePersoanainunitatenew": "persoane/persoanaunitate/persoanainunitatenew",
		"comisii": "comisii",
		"comisiiNew": "comisii/new",
		"comisieNew": "persoaneComisie/new",
		"controloriVizaCFP": "persoane/controloriVizaCFP",
		"controloriVizaCFPnew": "persoane/controloriVizaCFPnew",
		"persoanaUnificarepersoane": "persoane/persoana/unificarepersoane",
		"bancaNomenclatorBanci": "persoane/banca/NomenclatorBanci/",
		"iban": "iban",
		"ibannew": "ibannew",
		"configConfig": "persoane/config/config/",
		"utilitatiAsociereutilitati": "persoane/utilitati/asociereutilitati",
		"utilitatiAsociereutilitatiNew": "persoane/utilitati/asociereutilitati/new"
	},
	"adrese": {
		"stradaIstoricstrada": "adrese/strada/istoricstrada",
		"stradaAdrese": "adrese/strada/adrese",
		"stradaAdresenew": "adrese/strada/adresenew",
		"zonarefiscala": "adrese/zonarefiscala",
		"regulazonarefiscala": "adrese/regulazonarefiscala",
		"tarlaparcela": "adrese/tarlaparcela",
		"tarlaparcelaNew": "adrese/tarlaparcela/new",
		"zonarefiscalaparcele": "adrese/zonarefiscalaparcele",
		"regulazonarefiscalaparcele": "adrese/regulazonarefiscalaparcele",
		"unificarestrazi": "adrese/unificarestrazi",
		"unificarenumere": "adrese/unificarenumere",
		"unificareblocuri": "adrese/unificareblocuri",
		"unificarescari": "adrese/unificarescari",
		"unificareapartamente": "adrese/unificareapartamente",
		"regiunetip": "adrese/regiunetip",
		"regiunetipNew": "adrese/regiunetip/new",
		"regiune": "adrese/regiune",
		"regiuneNew": "adrese/regiune/new",
		"zonareadministrativa": "adrese/zonareadministrativa",
		"regulazonareadministrativa": "adrese/regulazonareadministrativa",
		"adreseconfig": "adrese/adreseconfig"
	},
	"documente": {
		"documentFurnizor": "document/furnizor",
		"documentNou": "documentNou",
		"documentfurnizorDetaliuNouMijlocFix": "documentfurnizor/detaliuNouMijlocFix",
		"documentfurnizorDetaliuNou": "documentfurnizor/detaliuNou",
		"documentefurnizoriFurnizoriDetaliunouserviciu": "documente/documentefurnizori/furnizori/detaliunouserviciu",
		"documentefurnizoriFurnizoriDetaliunouutilitati": "documente/documentefurnizori/furnizori/detaliunouutilitati",
		"documentefurnizoriFurnizoriDetaliunouobiective": "documente/documentefurnizori/furnizori/detaliunouobiective",
		"documentefurnizoriFurnizoriDetaliunoumodernizari": "documente/documentefurnizori/furnizori/detaliunoumodernizari",
		"documentfurnizorCompletareMultiplaEFactura": "documentfurnizor/completareMultiplaEFactura",
		"documentfurnizorEFacturaReceptionareMateriale": "documentfurnizor/eFacturaReceptionareMateriale",
		"documentfurnizorEFacturaReceptionareMF": "documentfurnizor/eFacturaReceptionareMF",
		"documentfurnizorEFacturaReceptionareOI": "documentfurnizor/eFacturaReceptionareOI",
		"documentfurnizorEFacturaReceptionareAlteValori": "documentfurnizor/eFacturaReceptionareAlteValori",
		"documentfurnizorEFacturaOperareUtilitati": "documentfurnizor/eFacturaOperareUtilitati",
		"documentfurnizorEFacturaOperareServicii": "documentfurnizor/eFacturaOperareServicii",
		"documentfurnizorEFacturaOperareObiectiveInCurs": "documentfurnizor/eFacturaOperareObiectiveInCurs",
		"documentfurnizorEFacturaOperareModernizariMF": "documentfurnizor/eFacturaOperareModernizariMF",
		"documentfurnizorProdusNou": "documentfurnizor/produsNou",
		"documentefurnizoriAvizeinfactura": "documente/documentefurnizori/avizeinfactura",
		"documentefurnizoriAjustareSume": "documente/documentefurnizori/ajustareSume",
		"documentefurnizoriFurnizoriDistribuiresume": "documente/documentefurnizori/furnizori/distribuiresume",
		"documentefurnizoriFurnizoriDistribuiresumenew": "documente/documentefurnizori/furnizori/distribuiresumenew",
		"documentFurnizorCuSoldInitial": "document/furnizorCuSoldInitial",
		"contracte": "documente/contracte",
		"contractNou": "documente/contractNou",
		"crediteAngajamentMultianual": "documente/crediteAngajamentMultianual",
		"comenzi": "comenzi/",
		"comandaNoua": "comandaNoua",
		"documentefurnizoriFurnizoriFinalizare": "documente/documentefurnizori/furnizori/finalizare",
		"clienti": "documente/clienti/",
		"clientiDocumentnou": "documente/clienti/documentnou",
		"clientiDetaliunou": "documente/clienti/detaliunou",
		"clientiDetaliunoudinstoc": "documente/clienti/detaliunoudinstoc",
		"clientiRefacturareUtilitati": "documente/clienti/refacturareUtilitati",
		"clientiDebitorNou": "documente/clienti/debitorNou",
		"clientiGenerareFacturiClientiRefacturareUtilitati": "documente/clienti/generareFacturiClientiRefacturareUtilitati",
		"clientiFinalizare": "documente/clienti/finalizare",
		"documentClientiCuSoldInitial": "document/clientiCuSoldInitial",
		"documenteclientiClientiDistribuiresumenew": "documente/documenteclienti/clienti/distribuiresumenew",
		"alteoperatiuni": "documente/alteoperatiuni/",
		"alteoperatiuninew": "documente/alteoperatiuninew",
		"eFactura": "documente/eFactura/",
		"eFacturaGenerareDocumenteFurnizor": "documente/eFactura/generareDocumenteFurnizor/",
		"executieSalariiImportResum": "executieSalariiImportResum",
		"listaExecutieSalarii": "listaExecutieSalarii",
		"executieSalariiEditare": "executieSalariiEditare",
		"executieSalariiCopiere": "executieSalariiCopiere",
		"executieSalarii": "executieSalarii",
		"executieSalariiCompletareCodIndicatorAngajament": "executieSalariiCompletareCodIndicatorAngajament",
		"executieSalariiPlataOP": "executieSalariiPlataOP",
		"executieSalariiAsociereStatAvansuri": "executieSalariiAsociereStatAvansuri",
		"executieSalariiEditareSuma": "executieSalariiEditareSuma",
		"vizacfpTipDocOpCFP": "vizacfp/tipDocOpCFP/",
		"vizacfpAsociere": "vizacfp/asociere",
		"vizacfpRegistruVizaCFP": "vizacfp/registruVizaCFP/",
		"vizacfpVizaManual": "vizacfp/vizaManual",
		"vizacfpVizareDocumente": "vizacfp/vizareDocumente/",
		"vizacfpAdaugareDocument": "vizacfp/adaugareDocument/",
		"clasificareProduse": "clasificareProduse/",
		"garantiiDeBunaExecutie": "garantiiDeBunaExecutie",
		"garantiiDeBunaExecutieAdaugareConstituireDeCatreFurnizor": "garantiiDeBunaExecutieAdaugareConstituireDeCatreFurnizor",
		"garantiiDeBunaExecutieAdaugareSoldInitial": "garantiiDeBunaExecutieAdaugareSoldInitial",
		"garantiiDeBunaExecutieAdaugareScrisoareGarantieBancara": "garantiiDeBunaExecutieAdaugareScrisoareGarantieBancara",
		"garantiiDeBunaExecutieAdaugarePolitaDeAsigurare": "garantiiDeBunaExecutieAdaugarePolitaDeAsigurare",
		"garantiiDeBunaExecutieAdaugareRestituire": "garantiiDeBunaExecutieAdaugareRestituire",
		"garantiiParticipareLicitatie": "garantiiParticipareLicitatie",
		"garantiiLicitatiiAdaugareSoldInitial": "garantiiLicitatiiAdaugareSoldInitial",
		"garantiiLicitatieAdaugarePolitaDeAsigurare": "garantiiLicitatieAdaugarePolitaDeAsigurare",
		"garantiiParticipareLicitatieAdaugareDeblocare": "garantiiParticipareLicitatieAdaugareDeblocare",
		"garantiiLicitatieAdaugareScrisoareBancara": "garantiiLicitatieAdaugareScrisoareBancara",
		"rapoarte": "rapoarte",
		"configurariConfig": "documente/configurari/config/",
		"configurariAlocarenumarchitanta": "documente/configurari/alocarenumarchitanta",
		"configurariAlocarenumarchitantaNew": "documente/configurari/alocarenumarchitanta/new",
		"configurariSetarivizacfp": "documente/configurari/setarivizacfp",
		"configurariAlocarenumarchitanta1": "documente/configurari/alocarenumarchitanta1",
		"configurariAlocarenumarchitantaNew1": "documente/configurari/alocarenumarchitanta/new1"
	},
	"buget": {
		"prevederiPrevederi": "buget/prevederi/Prevederi/",
		"prevederiBuget": "buget/PrevederiBuget/",
		"estimariIndiciEstimari": "buget/estimari/indiciEstimari",
		"executieExecutieExecutiebugetara": "buget/executie/executie/executiebugetara/",
		"executieSupldimangajamentbugetar": "executie/supldimangajamentbugetar",
		"executieAngajamentbugetar": "executie/angajamentbugetar",
		"executieSupldimangajamentlegal": "executie/supldimangajamentlegal",
		"executieAngajamentlegal": "executie/angajamentlegal",
		"executieCorectieCodAngajamentIndicaor": "executie/corectieCodAngajamentIndicaor",
		"executieExecutieLichidari": "buget/executie/executie/lichidari",
		"executieOrdonantare": "executie/ordonantare",
		"executieExecutieBugetaraExtrasCont": "executie/executieBugetaraExtrasCont/",
		"executieCererideschidericredite": "executie/cererideschidericredite",
		"executieCererideschidericredite_raportcentralizator": "executie/cererideschidericredite_raportcentralizator",
		"executieCererideschidericreditenew": "executie/cererideschidericreditenew",
		"executieExecutieDeschideridecredite": "buget/executie/executie/deschideridecredite",
		"executieExecutieDeschidericreditenew": "executie/executie/deschidericreditenew",
		"executieExecutieTransferSolduri": "buget/executie/executie/transferSolduri/",
		"executieExecutieGenerareOrdonantariDocumenteFurnizor": "buget/executie/executie/generareOrdonantariDocumenteFurnizor/",
		"rectificari": "buget/Rectificari",
		"restaurareRectificari": "buget/restaurareRectificari",
		"bugetNew": "Buget/Buget/new",
		"editareRectificare": "editareRectificare",
		"platiCheltuieli": "buget/PlatiCheltuieli",
		"rapoarteListarerapoarte": "buget/rapoarte/listarerapoarte",
		"nomenclatorDetalieri": "buget/Nomenclator/detalieri",
		"nomenclatorDetalieriNew": "buget/Nomenclator/detalieri/new",
		"nomenclatorCodAngajament": "buget/Nomenclator/codAngajament/",
		"nomenclatorCodAngajamentNew": "buget/Nomenclator/codAngajament/new",
		"formularFormularFormularDetalieri_v2": "buget/formular/formular/formularDetalieri_v2/",
		"formularFormularFormulardetalieriNew": "buget/formular/formular/formulardetalieri/new",
		"formularFormularFormulardetalieriPersonalizare": "buget/formular/formular/formulardetalieri/personalizare",
		"formularFormularFormuledetalieri": "buget/formular/formular/formuledetalieri/",
		"formularBugetFormularBuget": "buget/formularBuget/formularBuget/",
		"formularbugetFormularbugetFormularbugetNew": "buget/formularbuget/formularbuget/formularbuget/new",
		"formularBugetFormuleBuget": "buget/FormularBuget/formuleBuget/",
		"configBuget": "buget/ConfigBuget"
	},
	"contab": {
		"actualizareBalantaV2": "contab/ActualizareBalantaV2",
		"analitice": "contab/Analitice",
		"analiticeAltfelNew": "contab/Analitice/Altfel/new",
		"analiticeVenitincasareNew": "contab/Analitice/Venitincasare/new",
		"analiticeCheltuialafinantareNew": "contab/Analitice/Cheltuialafinantare/new",
		"analiticeExcedentNew": "contab/Analitice/Excedent/new",
		"generareAnalitice": "contab/GenerareAnalitice",
		"generareAnaliticeVenit": "contab/generareAnaliticeVenit",
		"notePropuse": "contab/notePropuse",
		"note": "contab/note",
		"noteCautareAvansata": "contab/noteCautareAvansata",
		"documenteAltedocumente": "documente/altedocumente/",
		"documenteAlteDocumenteNew": "documente/alteDocumenteNew",
		"documenteAlteDocumenteNotaNew": "documente/alteDocumenteNotaNew",
		"soldinitialpartener": "contab/soldinitialpartener",
		"inchideri": "contab/Inchideri/",
		"jurnalGeneral": "contab/jurnalGeneral/",
		"jurnalGeneralNew": "contab/jurnalGeneral/new",
		"centralizatoareCentralizatorFisaContului": "contab/centralizatoare/centralizatorFisaContului/",
		"vizualizareBalanta": "contab/VizualizareBalanta/",
		"facturiRestanteFurnizori": "contab/facturiRestanteFurnizori/",
		"registruJurnal": "contab/registruJurnal/",
		"registruInventar": "contab/registruInventar/",
		"registruCarteMare": "contab/registruCarteMare/",
		"registruCarteMareSah": "contab/registruCarteMareSah/",
		"situatieparteneriV3": "contab/situatieparteneriV3",
		"diferenteBalantaSitParteneri": "contab/diferenteBalantaSitParteneri",
		"generareDebite": "contab/GenerareDebite",
		"d394": "Contab/D394/",
		"d394New": "Contab/D394New",
		"d394IncasareNew": "Contab/D394IncasareNew",
		"d394FacturaNew": "Contab/D394FacturaNew",
		"d394SerieNew": "Contab/D394SerieNew",
		"fisaInregistrariContabile": "contab/fisaInregistrariContabile/",
		"rapoarteSituatieCheltuieliCentreCheltuiala": "contab/rapoarte/SituatieCheltuieli/CentreCheltuiala/",
		"rapoarteSituatiiZilnice": "contab/rapoarte/situatiiZilnice/",
		"rapoarteJurnaleAnalitice": "contab/rapoarte/jurnaleAnalitice/",
		"rapoarteSituatieFacturiPlatiAferente": "contab/rapoarte/situatieFacturiPlatiAferente/",
		"rapoarteRaportJurnalCumparari": "contab/rapoarte/raportJurnalCumparari/",
		"rapoarteRaportJurnalVanzari": "contab/rapoarte/raportJurnalVanzari/",
		"centralizatoareCentralizatorBalantaSetari": "contab/centralizatoare/centralizatorBalantaSetari/",
		"centralizatoareCentralizatorBalanta": "contab/centralizatoare/centralizatorBalanta/",
		"centralizatoareCentralizatorFisacontuluiSetari": "contab/centralizatoare/centralizatorFisacontuluiSetari/",
		"centralizatoareCentralizatorFisaContului2": "contab/centralizatoare/centralizatorFisaContului2/",
		"centralizatorAE": "Contab/centralizatorAE/",
		"inchidereLuna": "contab/InchidereLuna/",
		"conturiInchidere": "contab/ConturiInchidere",
		"asociereContInchidere": "contab/AsociereContInchidere",
		"cautareDocumente": "contab/cautareDocumente",
		"diferenteSumeBugetConta": "contab/DiferenteSumeBugetConta",
		"conturi": "contab/Conturi",
		"conturiNew": "contab/Conturi/new",
		"jurnale": "contab/Jurnale",
		"jurnaleEditJurnal": "contab/Jurnale/editJurnal",
		"sabloaneDeConturi": "contab/sabloaneDeConturi",
		"sabloaneDeConturiNew": "contab/SabloaneDeConturi/new",
		"sabloaneConturiPeOperatiuni": "contab/sabloaneConturiPeOperatiuni",
		"algoritmiDeGenerareNoteContabile": "contab/algoritmiDeGenerareNoteContabile",
		"algoritmiDeGenerareNoteContabileNew": "contab/algoritmiDeGenerareNoteContabileNew",
		"generatorNoteVenituri": "contab/generatorNoteVenituri",
		"generatorNoteVenituriNew": "contab/generatorNoteVenituri/new",
		"generatorNoteVenituriCopiereSabloane": "contab/generatorNoteVenituriCopiereSabloane",
		"sabloaneConturiInchidere": "contab/SabloaneConturiInchidere",
		"adaugaresabloncontinchidere": "contab/adaugaresabloncontinchidere",
		"asociereContTipCreditBugetar": "contab/asociereContTipCreditBugetar",
		"asociereContTipCreditBugetarNew": "contab/asociereContTipCreditBugetar/new",
		"asociereContTipCreditBugetarV2": "contab/asociereContTipCreditBugetarV2",
		"asociereContTipCreditBugetarV2New": "contab/asociereContTipCreditBugetarV2/new",
		"asociereContTipCreditBugetarV3": "contab/asociereContTipCreditBugetarV3",
		"asociereContTipCreditBugetarV3New": "contab/asociereContTipCreditBugetarV3/new",
		"conta": "Contab/Conta",
		"grupareConturi": "contab/grupareConturi/",
		"grupareConturiNew": "contab/grupareConturi/new/",
		"setariraportbalanta": "contab/setariraportbalanta",
		"setariParametriiRaport": "contab/setariParametriiRaport"
	},
	"inventare": {
		"obiectiveInCursDeExecutie": "obiectiveInCursDeExecutie",
		"obiectiveInCursDeExecutiePvobiectiveincursnew": "obiectiveInCursDeExecutie/pvobiectiveincursnew",
		"obiectiveInCursDeExecutieStocNou": "inventare/obiectiveInCursDeExecutie/stocNou",
		"iesiri": "iesiri",
		"iesiriIesireNoua": "iesiri/iesireNoua",
		"iesiriIesireDinPropuneri": "iesiri/iesireDinPropuneri",
		"iesiriOperareProdusDisponibil": "iesiri/operareProdusDisponibil",
		"transferuri": "transferuri",
		"transferuriTransferurinew": "transferuri/transferurinew",
		"transferuriMFtoOI": "transferuri/MFtoOI",
		"transferuriOperareProdusDisponibil": "transferuri/operareProdusDisponibil",
		"transferuriOperareMFtoOI": "transferuri/operareMFtoOI",
		"transferuriOperareMFtoOI_ng2": "transferuri/operareMFtoOI_ng2",
		"transformari": "transformari",
		"transformariTransformarinew": "transformari/transformarinew",
		"transformariOperareProdusDisponibil": "transformari/operareProdusDisponibil",
		"transformariAdaugaProdusObtinut": "transformari/adaugaProdusObtinut",
		"restituiri": "restituiri",
		"restituiriRestituirinew": "restituiri/restituirinew",
		"restituiriOperareProdusIesit": "restituiri/operareProdusIesit",
		"dareconsumConsumalimente": "inventare/dareconsum/consumalimente",
		"dareconsumConsumalimenteNew": "inventare/dareconsum/consumalimente/new",
		"dareconsumConsumalimentesuplimentariNew": "inventare/dareconsum/consumalimentesuplimentari/new",
		"necesarAprovizionare": "inventare/necesar/aprovizionare",
		"propuneriDeCasare": "inventare/propuneriDeCasare",
		"propuneriPropunereNoua": "propuneri/propunereNoua",
		"propuneriSelectieProduse": "propuneri/selectieProduse",
		"catalogmijloacefixe": "inventare/catalogmijloacefixe",
		"catalogmijloacefixeModernizari": "inventare/catalogmijloacefixe/modernizari",
		"catalogmijloacefixeModernizarinew": "inventare/catalogmijloacefixe/modernizarinew",
		"catalogmijloacefixeScadentar": "inventare/catalogmijloacefixe/scadentar",
		"calculamortizari": "inventare/calculamortizari",
		"caracteristiciMijloaceFixe": "inventare/caracteristiciMijloaceFixe",
		"catalogmijloacefixeCautaremijlocfix": "inventare/catalogmijloacefixe/cautaremijlocfix",
		"reevaluari": "inventare/reevaluari",
		"reevaluariReevaluarenoua": "inventare/reevaluari/reevaluarenoua",
		"stocInitial": "inventare/StocInitial",
		"stocNou": "inventare/stocNou",
		"receptie": "receptie",
		"receptiiCorectiedetaliere": "inventare/receptii/corectiedetaliere",
		"receptiiReceptieNoua": "receptii/receptieNoua",
		"inventar": "Inventare/Inventar/",
		"rapoarteBalanta": "inventare/rapoarte/balanta",
		"situatiiSituatiiamortizari": "inventare/situatii/situatiiamortizari",
		"rapoarteFisaproduse": "inventare/rapoarte/fisaproduse",
		"rapoarteListareraport": "inventare/rapoarte/listareraport",
		"categorieProduse": "inventare/categorieProduse",
		"categorieProdusenew": "inventare/categorieProdusenew",
		"modOrganizareMF": "inventare/modOrganizareMF",
		"modOrganizareMFnew": "inventare/modOrganizareMFnew",
		"tipFinantare": "inventare/tipFinantare",
		"tipFinantareMFnew": "inventare/tipFinantareMFnew",
		"registruProduse": "inventare/RegistruProduse/",
		"registruproduseCorectie": "inventare/registruproduse/corectie",
		"registruproduseCorectiemultipla": "inventare/registruproduse/corectiemultipla",
		"nomenclatorIpc": "inventare/nomenclator/ipc",
		"asociereProdusAliment": "inventare/asociereProdusAliment",
		"registruproduseUnificareproduse": "inventare/registruproduse/unificareproduse",
		"setariInventare": "setari/inventare"
	},
	"raportarifinanciare": {
		"contabBalantadeschidere": "raportarifinanciare/contab/balantadeschidere/",
		"contabExecutienontrezor": "raportarifinanciare/contab/executienontrezor",
		"contabBalantaverificare": "raportarifinanciare/contab/balantaverificare/",
		"contabFormular30PlatiRestante": "raportarifinanciare/contab/formular30PlatiRestante",
		"contabFormular19": "raportarifinanciare/contab/formular19",
		"contabFormular27": "raportarifinanciare/contab/formular27",
		"contabFormular28": "raportarifinanciare/contab/formular28",
		"contabFormular40": "raportarifinanciare/contab/formular40",
		"contabBalantadecembrie": "raportarifinanciare/contab/balantadecembrie",
		"contabFormular34": "raportarifinanciare/contab/formular34/",
		"contabFormular35a": "raportarifinanciare/contab/formular35a/",
		"contabFormular35c": "raportarifinanciare/contab/formular35c/",
		"contabFormular37": "raportarifinanciare/contab/formular37/",
		"contabAlteanexe": "raportarifinanciare/contab/alteanexe",
		"contabRaportarifinanciare": "raportarifinanciare/contab/raportarifinanciare",
		"bugetBugetindividual": "raportarifinanciare/buget/bugetindividual/",
		"bugetBugetagregat": "raportarifinanciare/buget/bugetagregat/",
		"bugetExportlotbuget": "raportarifinanciare/buget/exportlotbuget/",
		"bugetExportLoturi": "raportarifinanciare/buget/exportLoturi/",
		"trezorerieContexecutie": "raportarifinanciare/trezorerie/contexecutie",
		"trezorerieCompletaresumacredit": "raportarifinanciare/trezorerie/completaresumacredit",
		"trezorerieActualizaresumacredit": "raportarifinanciare/trezorerie/actualizaresumacredit",
		"trezorerieCorectieCAB": "raportarifinanciare/trezorerie/corectieCAB",
		"trezorerieDocumenteCAB": "raportarifinanciare/trezorerie/documenteCAB",
		"trezorerieSoldInitial": "raportarifinanciare/trezorerie/soldInitial",
		"trezorerieListasoldInitial": "raportarifinanciare/trezorerie/listasoldInitial",
		"configurariConfig": "raportarifinanciare/configurari/config/",
		"configurariFormulare": "raportarifinanciare/configurari/formulare/",
		"exportExportContaAs": "raportarifinanciare/export/exportContaAs",
		"exportExportCurteaDeConturi": "raportarifinanciare/export/exportCurteaDeConturi",
		"darsBilantSituatiiFormulare": "raportarifinanciare/DarsBilantSituatii/formulare",
		"darsBilantSituatiiFormulareDetalii": "raportarifinanciare/DarsBilantSituatii/formulareDetalii",
		"forexebugDocumente": "raportarifinanciare/forexebug/documente"
	},
	"impotax": {
		"casierieRamasitaCasierieRamasita": "impotax/casierie/ramasita/casierie/ramasita",
		"casierieRamasitaCasierieRamasitaNew": "impotax/Casierie/Ramasita/casierie/Ramasita/new",
		"ramasitaScadereMultiple": "ramasita/scadereMultiple",
		"debiteCompensariDebiteBorderoucompensari": "impotax/debite/compensari/debite/borderoucompensari",
		"debiteCompensariDebiteCompensare": "impotax/debite/compensari/debite/compensare",
		"auto": "impotax/Auto",
		"autoNew": "impotax/Auto/new",
		"autoSearch": "impotax/auto/search",
		"teren": "impotax/Teren",
		"terenNew": "impotax/Teren/new",
		"terenSearch": "impotax/Teren/search",
		"scutire": "impotax/Scutire",
		"coproprietari": "impotax/Coproprietari",
		"cladire": "impotax/Cladire",
		"cladireNew": "impotax/Cladire/new",
		"amenda": "impotax/Amenda",
		"amendaNew": "impotax/Amenda/new",
		"amendaSearch": "impotax/Amenda/search",
		"autoLente": "impotax/AutoLente",
		"autoLenteNew": "impotax/AutoLente/new",
		"taxaFirma": "impotax/TaxaFirma",
		"taxaFirmaNew": "impotax/TaxaFirma/new",
		"alteDebite": "impotax/AlteDebite",
		"alteDebiteNew": "impotax/AlteDebite/new",
		"testTest": "test/test/",
		"contracteNomenclatorUnitate": "contracte/Nomenclator/unitate/",
		"dUPLICATContracteContract": "DUPLICATContracte/Contract/",
		"valoriValoriCorectieTeren": "impotax/valori/ValoriCorectieTeren",
		"valoriValoriDebitExecutareSilita": "impotax/valori/ValoriDebitExecutareSilita",
		"valoriValoriAuto": "impotax/valori/ValoriAuto",
		"valoriValoriTerenuriAgricole": "impotax/valori/ValoriTerenuriAgricole",
		"valoriValoriSpectacole": "impotax/valori/ValoriSpectacole",
		"valoriValoriTipAutoLente": "impotax/valori/ValoriTipAutoLente",
		"valoriValoriTerenuriExtravilanCateg": "impotax/valori/ValoriTerenuriExtravilanCateg",
		"valoriValoriTaxeLunare": "impotax/valori/ValoriTaxeLunare",
		"valoriValoriCladiriTipuri": "impotax/valori/ValoriCladiriTipuri",
		"menuValori": "impotax/MenuValori",
		"valoriValoriTerenuriIntravilan": "impotax/valori/ValoriTerenuriIntravilan",
		"valoriValoriTaxaFirma": "impotax/valori/ValoriTaxaFirma",
		"valoriValoriReduceriProcenteCladiri": "impotax/valori/ValoriReduceriProcenteCladiri",
		"valoriCladiriFiz": "impotax/ValoriCladiriFiz",
		"valoriValoriProcenteMajorareCladiriFiz": "impotax/valori/ValoriProcenteMajorareCladiriFiz",
		"valoriValoriProcentSubsol": "impotax/valori/ValoriProcentSubsol",
		"valoriValoriRangZona": "impotax/valori/ValoriRangZona",
		"valoriCladiriJur": "impotax/ValoriCladiriJur",
		"valoriValoriCoeficientiMajorareCL": "impotax/valori/ValoriCoeficientiMajorareCL",
		"valoriValoripoprire": "impotax/valori/valoripoprire",
		"generic": "impotax/Generic",
		"genericNew": "impotax/Generic/new",
		"tipuriScutiri": "impotax/TipuriScutiri",
		"tipuriScutiriNew": "impotax/TipuriScutiri/new",
		"banciPlataPOS": "impotax/BanciPlataPOS",
		"banciPlataPOSNew": "impotax/BanciPlataPOS/new",
		"sarbatoriLegale": "impotax/SarbatoriLegale",
		"sarbatoriLegaleNew": "impotax/SarbatoriLegale/new",
		"tipCertificat": "impotax/TipCertificat",
		"tipCertificatNew": "impotax/TipCertificat/new",
		"procenteDobandaPenalitate": "impotax/ProcenteDobandaPenalitate",
		"procenteDobandaPenalitateTranse": "impotax/ProcenteDobandaPenalitateTranse",
		"procenteDobandaPenalitateTranseNew": "impotax/ProcenteDobandaPenalitateTranse/new",
		"menuGenerice": "impotax/MenuGenerice",
		"grupuriVenit": "impotax/GrupuriVenit",
		"grupVenit": "impotax/GrupVenit",
		"grupVenitNew": "impotax/GrupVenit/new",
		"borderouPosta": "impotax/BorderouPosta",
		"borderouPostaDocumente": "impotax/BorderouPosta/Documente",
		"borderouPostaDetalii": "impotax/BorderouPosta/Detalii",
		"borderouPostaStarePosta": "impotax/BorderouPosta/StarePosta",
		"borderouPostaExplicatie": "impotax/BorderouPosta/Explicatie",
		"tipCaenNew": "impotax/TipCaen/new",
		"impotaxconfig": "impotax/impotaxconfig/"
	},
	"incasari": {
		"impotaxCasierieCasaCasierieCasaIncasare": "impotax/casierie/casa/casierie/casa/incasare",
		"impotaxCasierieCasaCasierieCasaIncasareparametrizataIncasari": "impotax/casierie/casa/casierie/casa/incasareparametrizata/incasari",
		"impotaxCasierieCasaCasierieCasaPlatafaradebitIncasari": "impotax/casierie/casa/casierie/casa/platafaradebit/incasari",
		"impotaxCasierieCasaCasieriePlatafaradebitfarapersoaneIncasari": "impotax/casierie/casa/casierie/platafaradebitfarapersoane/incasari",
		"impotaxCasierieCasaCasierieCasaPlatafaradebitgarantieIncasari": "impotax/casierie/casa/casierie/casa/platafaradebitgarantie/incasari",
		"impotaxCasierieCasaCasierieCasaRestituire": "impotax/casierie/casa/casierie/casa/restituire",
		"impotaxCasierieCasaCasierieCasaRestituiregarantie": "impotax/casierie/casa/casierie/casa/restituiregarantie",
		"impotaxCasierieCasaGestionareincasarifarapersoana": "impotax/casierie/casa/gestionareincasarifarapersoana",
		"impotaxCasierieCasaCasierieCasaIncasarefacturi": "impotax/casierie/casa/casierie/casa/incasarefacturi",
		"impotaxCasierieCasaCasierieCasaPlatadirecta": "impotax/casierie/casa/casierie/casa/platadirecta",
		"impotaxCasierieCasaBorderouincasari": "impotax/casierie/casa/borderouincasari",
		"impotaxCasierieCasaValidareincasaridepunerenumerar": "impotax/casierie/casa/validareincasaridepunerenumerar",
		"platiDocumenteDocumentePredarebani": "incasariplati/documente/documente/predarebani",
		"platiRegistruCasaSimplificat": "incasariplati/registruCasaSimplificat",
		"platiRegistruCasaSimplificatCecridicarenumerarsimplificat": "incasariplati/registruCasaSimplificat/cecridicarenumerarsimplificat",
		"platiRegistruCasaSimplificatPlatafaradebit": "incasariplati/registruCasaSimplificat/platafaradebit",
		"platiRegistruCasaSimplificatIncasarecuchitanta": "incasariplati/registruCasaSimplificat/incasarecuchitanta",
		"platiRegistrucasasimplificatRegularizare": "incasariplati/registrucasasimplificat/regularizare",
		"platiRegistrucasasimplificatPlatafaradebitfarapersoana": "incasariplati/registrucasasimplificat/platafaradebitfarapersoana",
		"platiRegistruCasaSimplificatPlatafaradebitGarantie": "incasariplati/registruCasaSimplificat/platafaradebitGarantie",
		"platiRegistrucasasimplificatRestituiregarantie": "incasariplati/registrucasasimplificat/restituiregarantie",
		"impotaxCasierieCasaSituatieincasari": "impotax/casierie/casa/situatieincasari",
		"platiValidareincasariposExtrascontincasaripos": "incasariplati/validareincasaripos/extrascontincasaripos",
		"platiValidareIncasariPOS": "incasariplati/validareIncasariPOS",
		"listaDocumentePlata": "listaDocumentePlata",
		"documentPlata": "documentPlata",
		"documentPlataInAfaraBugetului": "documentPlataInAfaraBugetului",
		"documentIncasare": "documentIncasare",
		"documentPlataRestituireGarantie": "documentPlataRestituireGarantie",
		"documentViramentInternMandatCheltuiala": "documentViramentInternMandatCheltuiala",
		"documentViramentInternMandatVenit": "documentViramentInternMandatVenit",
		"listaDocumentePlataOPME": "listaDocumentePlataOPME",
		"documentPlataOPME": "documentPlataOPME",
		"platiDocumenteplataDocumenteplataBorderouop": "incasariplati/documenteplata/documenteplata/borderouop",
		"platiIncasaribancaIncasaribancaListaincasari": "incasariplati/incasaribanca/incasaribanca/listaincasari",
		"platiIncasaribancaAlteincasari": "incasariplati/incasaribanca/alteincasari",
		"platiDocumenteListacec": "incasariplati/documente/listacec",
		"platiDocumenteCecridicarenumeredetaliu": "incasariplati/documente/cecridicarenumeredetaliu",
		"platiDocumenteCecridicarenumere": "incasariplati/documente/cecridicarenumere",
		"platiDocumenteAvansuri": "incasariplati/documente/avansuri",
		"platiDocumenteAvansurinew": "incasariplati/documente/avansurinew",
		"platiDocumenteDocumenteAvansdecont": "incasariplati/documente/documente/avansdecont",
		"platiDocumenteOrdindeplatanew": "incasariplati/documente/ordindeplatanew",
		"platiDocumenteDeplasari": "incasariplati/documente/deplasari",
		"platiDocumenteDeplasarinew": "incasariplati/documente/deplasarinew",
		"platiDocumenteAdddocjustificativ": "incasariplati/documente/adddocjustificativ",
		"platiDocumenteDispozitiideplata": "incasariplati/documente/dispozitiideplata",
		"platiDocumenteDispozitiideplatanew": "incasariplati/documente/dispozitiideplatanew",
		"platiDocumenteDispozitiideincasare": "incasariplati/documente/dispozitiideincasare",
		"platiDocumenteDispozitiideincasarenew": "incasariplati/documente/dispozitiideincasarenew",
		"platiDocumenteFoiDeVarsamant": "incasariplati/documente/FoiDeVarsamant/",
		"platiDocumenteFoiDeVarsamantAdaugare": "incasariplati/documente/FoiDeVarsamantAdaugare/",
		"platiSituatiiRegistruCasa": "incasariplati/situatii/registruCasa",
		"platiSituatiiSituatiiBorderoupunctdelucru": "incasariplati/situatii/situatii/borderoupunctdelucru",
		"platiSituatiiSituatiiFisapersoana": "incasariplati/situatii/situatii/fisapersoana",
		"platiDocumenteStatdeplatanumerar": "incasariplati/documente/statdeplatanumerar",
		"platiSituatiiSituatiiBorderou": "incasariplati/situatii/situatii/borderou",
		"platiSituatiiSituatiiListaordinplata": "incasariplati/situatii/situatii/listaordinplata",
		"platiSituatiiSituatiiListarerapoarte": "incasariplati/situatii/situatii/listarerapoarte",
		"alteincasariIncasareEpay": "ng/incasari/plati/alteincasari/incasare/epay",
		"platiAlteincasariIncasareghiseul": "incasariplati/alteincasari/incasareghiseul",
		"platiIncasareConfigincasare": "incasariplati/incasare/configincasare",
		"alteincasariConfigGpay": "ng/incasari/plati/alteincasari/config/gpay",
		"platiAlteincasariConfigghiseul": "incasariplati/alteincasari/configghiseul/",
		"raportarifinanciareTrezorerieExtrascont": "raportarifinanciare/trezorerie/extrascont",
		"raportarifinanciareTrezorerieImportextrascont": "raportarifinanciare/trezorerie/importextrascont",
		"raportarifinanciareTrezorerieTrezorerieExtrascontincasare": "raportarifinanciare/trezorerie/trezorerie/extrascontincasare"
	},
	"achizitii": {
		"propuneri": "achizitii/Propuneri",
		"propuneriTrimite": "achizitii/PropuneriTrimite",
		"propuneriPropuneriv2New": "achizitii/propuneri/propuneriv2/new",
		"propuneriPropuneriincadrarecpv": "achizitii/propuneri/propuneriincadrarecpv",
		"propuneriPropuneristerse": "achizitii/propuneri/propuneristerse",
		"propuneriPropuneriistoric": "achizitii/propuneri/propuneriistoric",
		"propuneriDetalii": "achizitii/propuneri/detalii",
		"propunerifisa": "achizitii/propunerifisa",
		"propuneriIncadrareBugetara": "achizitii/Propuneri/incadrareBugetara/",
		"propuneriPropuneriananterior": "achizitii/propuneri/propuneriananterior",
		"programanual": "achizitii/Programanual",
		"surseFinantare": "achizitii/SurseFinantare",
		"surseFinantarenew": "achizitii/SurseFinantarenew",
		"programanualProgramanualv2New": "achizitii/programanual/programanualv2/new",
		"programanualProgramanualv2pereferate": "achizitii/programanual/programanualv2pereferate",
		"programnew": "achizitii/Programnew",
		"propuneriPropunericentralizator": "achizitii/propuneri/propunericentralizator",
		"propuneriAjustarepropuneri": "achizitii/propuneri/ajustarepropuneri",
		"propuneriCentralizatorcpv": "achizitii/propuneri/centralizatorcpv",
		"referateNecesitate": "achizitii/ReferateNecesitate",
		"referateNecesitateFisa": "achizitii/ReferateNecesitateFisa",
		"referateNecesitateSurseFinantare": "achizitii/ReferateNecesitateSurseFinantare",
		"referateNecesitateSurseFinantarenew": "achizitii/ReferateNecesitateSurseFinantarenew",
		"referateReferatenecesitatev2New": "achizitii/referate/referatenecesitatev2/new",
		"referateReferatenecesitatesterse": "achizitii/referate/referatenecesitatesterse",
		"referateReferatnecesitateistoric": "achizitii/referate/referatnecesitateistoric",
		"referateReferatenecesitatedetaliu": "achizitii/referate/referatenecesitatedetaliu",
		"referateNecesitateDetaliinew": "achizitii/ReferateNecesitateDetaliinew",
		"referateNecesitateIstoric": "achizitii/ReferateNecesitateIstoric",
		"formularAchizitii": "achizitii/formularAchizitii/",
		"formularAchizitiiNew": "achizitii/formularAchizitiiNew",
		"oferte": "achizitii/oferte",
		"proceduriachizitiiOfertenew": "achizitii/proceduriachizitii/ofertenew",
		"atasareReferat": "achizitii/atasareReferat",
		"etape": "achizitii/Etape",
		"criterii": "achizitii/Criterii",
		"criteriinew": "achizitii/Criteriinew",
		"achizitii": "Achizitii/Achizitii"
	},
	"clienti": {
		"documenteContracteContracteclientContracteContracteclientNewClienti": "documente/contracte/contracteclient/contracte/contracteclient/new/clienti",
		"documentClient": "document/client",
		"contracteClient": "contracte/client",
		"contracteContracteClientNew": "contracte/contracteClient/new",
		"configConfig": "clienti/config/config/"
	},
	"resum": {
		"pontaj": "resum/pontaj"
	},
	"salarii": {
		"nomenclatorFinantari": "salarii/Nomenclator/Finantari/",
		"nomenclatorFinantariNew": "salarii/Nomenclator/Finantari/new"
	},
	"asisoc": {
		"familia": "asisoc/Familia",
		"familiaNew": "asisoc/Familia/new",
		"registreTipajutor": "asisoc/registre/tipajutor",
		"dgaspcListacerere": "asisoc/dgaspc/listacerere",
		"dgaspcDgaspc": "asisoc/dgaspc/dgaspc",
		"dgaspcHandicapHandicap": "asisoc/dgaspc/handicap/handicap",
		"dgaspcHandicapDgaspcHandicapRaspuns": "asisoc/dgaspc/handicap/dgaspc/handicap/raspuns",
		"dgaspcTichetparcareTicheteparcare": "asisoc/dgaspc/tichetparcare/ticheteparcare",
		"dgaspcTicheteparcareTicheteparcareRaspuns": "asisoc/dgaspc/ticheteparcare/ticheteparcare/raspuns",
		"dgaspcVignetaVigneta": "asisoc/dgaspc/vigneta/vigneta",
		"dgaspcVignetaVigneteRaspuns": "asisoc/dgaspc/vigneta/vignete/raspuns",
		"dgaspcExtrasCfExtras": "asisoc/dgaspc/extrasCf/extras",
		"dgaspcExtrascfExtrascfRaspuns": "asisoc/dgaspc/extrascf/extrascf/raspuns",
		"dgaspcScutireimpozitScutire": "asisoc/dgaspc/scutireimpozit/scutire",
		"dgaspcScutireimpozitScutireRaspuns": "asisoc/dgaspc/scutireimpozit/scutire/raspuns",
		"dgaspcDetalii": "asisoc/dgaspc/detalii",
		"dgaspcCertificatnou": "asisoc/dgaspc/certificatnou",
		"asisocConfigAsisoc": "Asisoc/asisoc/configAsisoc"
	},
	"agroregis": {
		"aplicatie": "agroregis/Aplicatie"
	},
	"nomenclator": {
		"nomgenSectorbugetarNomenclatorSectorbugetar": "nomgen/sectorbugetar/nomenclator/sectorbugetar",
		"nomgenSectorbugetarNomenclatorSectorbugetarNew": "nomgen/sectorbugetar/nomenclator/sectorbugetar/new",
		"nomgenSursafinantareNomenclatorSursafinantare": "nomgen/sursafinantare/nomenclator/sursafinantare",
		"nomgenSursafinantareNomenclatorSursafinantareNew": "nomgen/sursafinantare/nomenclator/sursafinantare/new",
		"nomgenNomenclatorClasificatiefunctionala": "nomgen/nomenclator/clasificatiefunctionala",
		"nomgenClasificatiefunctionalaNomenclatorClasificatiefunctionalaNew": "nomgen/clasificatiefunctionala/nomenclator/clasificatiefunctionala/new",
		"nomgenClasificatieeconomicaNomenclatorClasificatieeconomica": "nomgen/clasificatieeconomica/nomenclator/clasificatieeconomica",
		"nomgenClasificatieeconomicaNomenclatorClasificatieeconomicaNew": "nomgen/clasificatieeconomica/nomenclator/clasificatieeconomica/new",
		"nomgenCodprogrambugetarNomenclatorCodprogrambugetar": "nomgen/codprogrambugetar/nomenclator/codprogrambugetar",
		"nomgenCodprogrambugetarNomenclatorCodprogrambugetarNew": "nomgen/codprogrambugetar/nomenclator/codprogrambugetar/new",
		"nomgenCursvalutarCursvalutar": "nomgen/cursvalutar/cursvalutar",
		"nomgenClasificareproduse": "nomgen/clasificareproduse",
		"nomgenClasificareproduseClasificarenoua": "nomgen/clasificareproduse/clasificarenoua",
		"nomgenFunctiiFunctii": "nomgen/functii/functii",
		"nomgenFunctiiFunctiiNew": "nomgen/functii/functii/new",
		"nomgenUtilitatiNomenclatorTipuriutilitati": "nomgen/utilitati/nomenclator/tipuriutilitati",
		"nomgenUtilitatiNomenclatorTipuriutilitatiNew": "nomgen/utilitati/nomenclator/tipuriutilitati/new",
		"nomgenUtilitatiNomenclatorTipuriservicii": "nomgen/utilitati/nomenclator/tipuriservicii",
		"nomgenUtilitatiNomenclatorTipuriserviciiNew": "nomgen/utilitati/nomenclator/tipuriservicii/new",
		"nomgenUtilitatiNomenclatorTipuriplatinumerar": "nomgen/utilitati/nomenclator/tipuriplatinumerar",
		"nomgenUtilitatiNomenclatorTipuriplatinumerarNew": "nomgen/utilitati/nomenclator/tipuriplatinumerar/new",
		"nomgenUtilitatiNomenclatorTipuriplatinumerarPersonalizare": "nomgen/utilitati/nomenclator/tipuriplatinumerar/personalizare",
		"nomgenCpv": "nomgen/cpv/",
		"nomgenTipuriVenituri": "nomgen/TipuriVenituri",
		"nomgenTipuriVenituriNew": "nomgen/TipuriVenituri/new",
		"nomgenDocumenteOperatiuniCFP": "nomgen/documente/operatiuniCFP",
		"nomgenDocumenteOperatiuniCFPNew": "nomgen/documente/operatiuniCFP/new",
		"nomgenDocumenteOperatiuniCFPCAsociereClasificatieEconomica": "nomgen/documente/operatiuniCFPCAsociereClasificatieEconomica",
		"nomgenDocumenteNomenclatorOperatiuniproprii": "nomgen/documente/nomenclator/operatiuniproprii/",
		"nomgenDocumenteNomenclatorOperatiunipropriiNew": "nomgen/documente/nomenclator/operatiuniproprii/new",
		"nomgenDocumenteNomenclatorOperatiunipropriiDetaliunew": "nomgen/documente/nomenclator/operatiuniproprii/detaliunew",
		"nomgenSabloaneDeConturi": "nomgen/sabloaneDeConturi",
		"nomgenSabloaneDeConturiNew": "nomgen/SabloaneDeConturi/new",
		"nomgenSabloaneConturiPeOperatiuni": "nomgen/sabloaneConturiPeOperatiuni",
		"nomgenAsociereContBancaCuTipBuget": "nomgen/asociereContBancaCuTipBuget/",
		"nomgenAsociereContBancaCuTipBugetNew": "nomgen/asociereContBancaCuTipBuget/new",
		"nomgenDarsbilantsituatiiDarsbilantsituatiiFormulardars": "nomgen/darsbilantsituatii/darsbilantsituatii/formulardars"
	},
	"deploy": {
		"databaseScript": "deploy/database/Script",
		"databaseValidate": "deploy/database/Validate",
		"dateGenerarePachete": "deploy/date/generarePachete",
		"dateGenerareLogDataService": "deploy/date/generareLogDataService",
		"dateRegistruImportDate": "deploy/date/registruImportDate",
		"dateRegistruImportDateNew": "deploy/date/registruImportDate/new",
		"surseDeploySurse": "deploy/surse/deploySurse",
		"surseValidaresurse": "deploy/surse/validaresurse",
		"administrare": "deploy/administrare"
	},
	"conversiidate": {
		"wizardWelcome": "conversiidate/wizard/Welcome/",
		"wizardSetup": "conversiidate/wizard/Setup/",
		"wizardPersoane": "conversiidate/wizard/Persoane/",
		"wizardUnitati": "conversiidate/wizard/Unitati/",
		"wizardBanci": "conversiidate/wizard/Banci/",
		"wizardBuget": "conversiidate/wizard/Buget/",
		"wizardGestStoc": "conversiidate/wizard/GestStoc",
		"wizardMiFix": "conversiidate/wizard/MiFix",
		"wizardContab": "conversiidate/wizard/Contab",
		"wizardFinalSetup": "conversiidate/wizard/FinalSetup/",
		"wizardVerificare": "conversiidate/wizard/verificare/"
	},
	"invatamant": {
		"grupe": "invatamant/grupe",
		"grupaGrupanew": "invatamant/grupa/grupanew",
		"prezenta": "invatamant/prezenta/",
		"rapoarteRestante": "invatamant/rapoarte/restante",
		"rapoarteValabilitateFlotant": "invatamant/rapoarte/valabilitateFlotant/",
		"configurariConfig": "invatamant/configurari/config",
		"configurariVacante": "invatamant/configurari/vacante",
		"configurariVacanteNew": "invatamant/configurari/vacante/new"
	},
	"cantina": {
		"cantinaPreparate": "cantina/cantina/preparate",
		"cantinaPreparateNew": "cantina/cantina/preparate/new",
		"cantinaMeniu": "cantina/cantina/meniu",
		"cantinaMeniuNew": "cantina/cantina/meniu/new",
		"cantinaPlanificare": "cantina/cantina/planificare",
		"planificareNew": "cantina/planificare/new",
		"cantinaListaalimenteperioada": "cantina/cantina/listaalimenteperioada",
		"cantinaListaalimentebucatar": "cantina/cantina/listaalimentebucatar",
		"cantinaListazilnicaalimente": "cantina/cantina/listazilnicaalimente",
		"cantinaAlergen": "cantina/cantina/alergen",
		"cantinaAlergenNew": "cantina/cantina/alergen/new",
		"cantinaAlimente": "cantina/cantina/alimente",
		"cantinaAlimenteNew": "cantina/cantina/alimente/new",
		"categoriiPersoane": "cantina/categorii/persoane",
		"categoriiPersoaneNew": "cantina/categorii/persoane/new",
		"oraservire": "cantina/oraservire/",
		"oraservireNew": "cantina/oraservire/new/",
		"preparat": "cantina/preparat",
		"categorieNew": "cantina/categorie/new/",
		"configCantinaconfig": "cantina/config/cantinaconfig",
		"configSoldinitial": "cantina/config/soldinitial",
		"configAsocierecategorii": "cantina/config/asocierecategorii",
		"asocierecategorieNew": "cantina/asocierecategorie/new",
		"configListasolduri": "cantina/config/listasolduri"
	},
	"investitii": {
		"obiectiveObiectivnou": "investitii/obiective/obiectivnou",
		"obiective": "investitii/obiective",
		"obiectiveCompletaresolduriinitiale": "investitii/obiective/completaresolduriinitiale",
		"configurariConfig": "investitii/configurari/config/"
	},
	"dev": {
		"setari": "dev/setari",
		"resursa": "dev/resursa",
		"resursaControl": "dev/resursa/control",
		"componentSamples": "dev/component/samples",
		"deploy": "dev/deploy",
		"releaseValidare": "dev/release/validare"
	},
	"user": {
		"cont": "user/cont",
		"setare": "user/setare",
		"theme": "user/theme",
		"notificari": "user/notificari",
		"email": "user/email",
		"accessDenied": "user/access/denied"
	}
}