export const routes = [
  {
    "application": {
      "nume": "Administrare sistem"
    },
    "module": {
      "nume": "Dezvoltare"
    },
    "url": "ng/admin/ngtest",
    "icon": "fal fa-file",
    "options": {},
    "title": "Angular2Test",
    "access": "/10/41/9/"
  },
  {
    "application": {
      "nume": "Încasări și plăți"
    },
    "module": {
      "nume": "Alte încasări"
    },
    "url": "ng/incasari/plati/alteincasari/incasare/epay",
    "icon": "fal fal fa-file",
    "options": {},
    "title": "Import GlobalPay.ro",
    "access": "/23/5/1/"
  },
  {
    "application": {
      "nume": "Încasări și plăți"
    },
    "module": {
      "nume": "Configurări"
    },
    "url": "ng/incasari/plati/alteincasari/config/gpay",
    "icon": "fal fal fa-file",
    "options": {},
    "title": "Setări GlobalPay.ro",
    "access": "/23/6/2/"
  }
]