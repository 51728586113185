import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { catchError, map, take, takeWhile } from 'rxjs/operators';

import { UserService } from "./UserService";
import { environment } from 'src/environments/environment.dev';

interface IWebSoketResponse {
    message: string | { [key: string]: any },
    event: string,
    type?: string,
}

@Injectable({ providedIn: 'root' })
export class WebSoketService {
    constructor(private user: UserService) {
        const w = window.location;
        let origin = w.origin ? w.origin : `${w.protocol}//${w.hostname}${(w.port ? `:${w.port}` : '')}`;
        if (!environment.production) { origin = origin.replace(environment['port'], environment['serverPort']) }
        this.wsUrl = `${origin.replace('http', 'ws')}/ws`;
    }

    private wsUrl: string;
    private socket: WebSocketSubject<any>;
    private subscription: Subject<IWebSoketResponse>;

    /** complete and close curent subscription and socket (for all) */
    close() {
        if (this.socket) {
            this.socket.complete();
            this.socket = null;
        }
        if (this.subscription) {
            this.subscription.complete(); // it unsubscribes all subscribers automaticaly
            this.subscription = null;
        }
    }

    /**
     * send message to server, creates new subscription, DON'T FORGET to unsubscribe
     * @param event 
     * @param message 
     * @returns subscription
     */
    send(event: string, message?: string | { [key: string]: any }): Observable<IWebSoketResponse | string | any>
    /**
     * @deprecated DO NOT USE WITH callback parameter
     * send message to server, creates new subscription, DON'T FORGET to unsubscribe
     * @param event 
     * @param message 
     * @returns subscription
     */
    send(event: string, message?: string | { [key: string]: any }, callback?: (data) => any): Observable<IWebSoketResponse | string | any>
    /**
     * creates new subscription, send message to server \
     * does not filter messages by events, will capture all events
     * 
     * @it unsubscribes automatically if message is instanceOf Error, contains event: 'error' or progress/procent: 100
     * @else DON'T FORGET to unsubscribe
     * 
     * @convertError - if true, will convert Error to IWebSoketResponse, otherwise throw Error
     * @returns subscription
     */
    send(event: string, message?: string | { [key: string]: any }, callback?: (data) => any, catchAndConvertError?: boolean) {
        this.connect();
        if (!this.subscription || this.subscription.closed) {
            this.subscription = new Subject();
            this.socket.pipe( // does not send message, only creates observable
                catchError(error => {
                    return catchAndConvertError ? of({ event: 'error', message: error.message }) : of(error);
                }),
                takeWhile(data => !(data instanceof Error), true),
                map(data => this.mapResults(data, catchAndConvertError)),
                takeWhile(data => !(data['progress'] == 100 || data['procent'] == 100 || data.event == 'error'), true),
            ).subscribe(this.subscription);
        }
        this.socket.next({ event: event, message: message, auth: `Bearer ${this.user.token}` });
        // TODO: remove callback parameter
        if (callback && event == 'executeQuery') { // only one response expected
            return <any>this.subscription.pipe(take(1)).subscribe(data => callback(data))
        }
        return this.subscription.asObservable();
    }

    /**
     * register and listen to server messages \
     * posts back if event is equal to the one provided, also includes 'error' and 'warning' events \
     * error event will throw Error => stops subscription
     * 
     * @it unsubscribes automatically if message is instanceOf Error, contains event: 'error' or progress/procent: 100
     * @otherwise DON'T FORGET to clean up subscription (unsubscribe)
     * @message - if no message is provided, only listens to server messages
     * @convertError - if true, will convert Error to IWebSoketResponse, otherwise throw Error
     * @returns subscription, can also send messages to server with .next(...)
     */
    on(event: string, message?: string | { [key: string]: any }, catchAndConvertError?: boolean) {
        this.connect();
        return this.socket.multiplex(
            () => ({ event, message, auth: `Bearer ${this.user.token}` }),
            () => ({ event: 'unsubscribe' }),
            (msg) => msg.type === event || msg.event === event || msg.event === 'error' || msg.event === 'warning'
        ).pipe(
            catchError(error => {
                return catchAndConvertError ? of({ event: 'error', message: error.message }) : of(error);
            }),
            takeWhile(data => !(data instanceof Error), true),
            map(data => this.mapResults(data, catchAndConvertError)),
            takeWhile(data => !(data['progress'] == 100 || data['procent'] == 100 || data.event == 'error'), true),
        )
    }

    /**
     * @deprecated, will be removed, use .on() instead
     * uses .on() and subscribes if callback is provided
     */
    register(event: string, callback?: (data: { event: string, message: any, progress?: number, procent?: number }) => void) {
        this.connect();
        const sub = this.socket.multiplex(
            () => ({ event: 'register', message: '', auth: `Bearer ${this.user.token}` }),
            () => ({ event: 'unsubscribe' }),
            (msg) => msg.type === event || msg.event === event || msg.event === 'error' || msg.event === 'warning'
        );
        if (callback) { sub.subscribe(data => callback(data.message)) }
        return sub;
    }

    private connect() {
        if (!this.socket || this.socket.closed) {
            this.close();
            this.socket = webSocket({ url: this.wsUrl });
        } else {
            console.log('socket ok, new socket not necessary')
        }
    }
    private mapResults(data, isConvert?: boolean): IWebSoketResponse {
        if (data instanceof Error) { console.error('socket error', data); throw data }
        if (typeof data.message == 'string' && data.message.length > 0) { // might be json
            try { data.message = JSON.parse(data.message) } catch (error) { }
        }
        if (data.event == 'error') {
            if (isConvert) { return data } else { throw data }
        }
        return data
    }
}