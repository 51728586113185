import { Injectable, TemplateRef } from "@angular/core";
import { map, catchError } from 'rxjs/operators';
import { of, Subscription } from "rxjs";
import { ActiveToast } from "ngx-toastr";

import { AppSettings } from './AppSettings';
import { DataService } from "./DataService";
import { LogService } from './LogService';
import { DialogService } from './DialogService';
import { WebSoketService } from './WebSocketService';

import { ReportFileType, ReportFolder } from '../constants/EnumCustom';
import { ReportFileTypeSelection } from '../controls/modal/dialog.report';
import { environment } from 'src/environments/environment.dev';

import { ConfigService } from "./ConfigService";
import { Config } from "../constants/Enum";

interface IReportRequest {
    app?: string; query?: string; subModul?: string; page?: string; params?: Object | any[];
}
export interface ReportConfigBase extends IReportRequest {
    exportFileType?: string | ReportFileType;
    reportName?: string;
}
export interface ReportConfig extends ReportConfigBase {
    reportPath: string;
    reportUrl?: string;
}
export interface ReportConfig2 extends ReportConfigBase {
    report: IReportRequest
}

@Injectable({ providedIn: 'root' })
export class ReportService {
    constructor(private ws: WebSoketService, private data: DataService,
        private dialog: DialogService, private settings: AppSettings,
        private log: LogService, private config: ConfigService) { }

    private toasts: any[] = [];
    private subscription: Subscription;

    /** Deschide automat raportul, nu mai apare toaster/dialog */
    private get isAutoOpen(): boolean { return this.settings.isReportAutoOpen }

    /** Formatul in care se genereaza raportul */
    set format(format: string | ReportFileType) {
        this.settings[Config.TipRaport$Administraresistem$Administraresistem] = format
    }
    get format(): string | ReportFileType {
        return this.settings[Config.TipRaport$Administraresistem$Administraresistem] || ReportFileType.pdf
    }

    /** @deprecated do not use */
    get defaultFormat(): string {
        return this.settings[Config.TipRaport$Administraresistem$Administraresistem]
    };
    /** @deprecated do not use */
    set defaultFormat(format: string) {
        this.settings[Config.TipRaport$Administraresistem$Administraresistem] = format
    };
    /** @deprecated do not use */
    get defaultTypeClass() { return this.getFormatIcon(this.format) }
    /** @deprecated to be removed */
    getTypeClass(format: string | ReportFileType) { return this.getFormatIcon(format) }
    /** @deprecated to be removed */
    initDefaultFormat() {
        const cfg = Config.TipRaport$Administraresistem$Administraresistem;
        this.config.get(cfg).then((setare) => this.defaultFormat = setare);
    }

    icon(format?: ReportFileType) { return this.getFormatIcon(format || this.format) }
    getFormatIcon(format: ReportFileType | string) {
        switch (format) {
            case ReportFileType.pdf: case 'pdf': return "text-danger fa-file-pdf";
            case ReportFileType.excel: case 'excel': case 'xls': case 'xlsx': return "text-success fa-file-excel";
            case ReportFileType.word: case 'word': case 'doc': case 'docx': return "text-info fa-file-word";
            case ReportFileType.csv: case 'csv': return "text-secondary fa-file-alt";
        }
    }

    setReportParams(
        template: TemplateRef<any>, templateContext: { [key: string]: any }, params?
    ): Promise<{ params: { [key: string]: any }, exportFileType: ReportFileType }> {
        return this.dialog.custom(ReportFileTypeSelection, {
            template: template, templateContextDate: templateContext, params: params || {},
        }).result
    }
    getCustomReport(idOperatie: number, idTipDocument: number, params?: { nrA?: number, nrC?: number }) {
        // const aici = `<span class="text-underline"><i class="fa fa-hand-point-right"></i> Click aici</span>`;
        // const message = `<a href="/${States.config.numere};idOperatie=${window.btoa(idOperatie.toString())}" target="_blank">${aici}</a>!`;
        // params = params || {};
        // return this.data.executeQuery(Query.comun.getNumeRaport(idOperatie, idTipDocument)).then(res => {
        //     if (!res.length) {
        //         this.log.warning(message, `Nu a fost setat nici un raport pentru aceast tip operatie (${Operatie[idOperatie]})!\n`, null, {
        //             enableHtml: true, timeOut: 6000, progressBar: true
        //         });
        //         throw `Configurati raport!`
        //     } else if (params.hasOwnProperty('nrA') || params.hasOwnProperty('nrC')) {
        //         params['nrA'] = res[0].exemplareConta;
        //         params['nrC'] = res[0].exemplareClient;
        //     }
        //     return res.length ? { reportName: res[0].nume, params, isSimplu: res[0].isSimplu } : {}
        // })
    }

    getReport2(folder: ReportFolder, name: string, params?: { [key: string]: any }, exportFileType?: string | ReportFileType) {
        return this.getReport({ reportPath: `${folder}${name}`, params, exportFileType })
    }

    /** 
     * generates report using `runReport`, subscribes to result and opens toats 
     * @reportConfig - config object for report
     * @returns subscription
     */
    getReport(config: IReportRequest | ReportConfig | ReportConfig2) {
        const ref = this.toastr('progress');
        this.subscription && this.subscription.unsubscribe(); // close previous subscription
        this.subscription = this.runReport(config).subscribe((url) => {
            this.toastr('close');
            this.openOrShow(url);
        });

        return this.subscription;
    }

    /** 
     * generate report, user will handle subscription and toasts/open
     * @returns observable, DON'T FORGET TO unsubscribe
     */
    runReport(config: IReportRequest | ReportConfig | ReportConfig2) {
        const { report, ...options } = <IReportRequest & ReportConfig & ReportConfig2>config;
        return this.ws.on('report', Object.assign({ exportFileType: this.format }, report, options)).pipe(
            map((data) => {
                if (data.event == 'error' || data.message['type'] == 'error') {
                    const error = data.message['message'].split('--->').join('\n');
                    this.log.error(error.length ? error : 'Eroare la generare raport!', null, error);
                    return null;
                }
                return <string>data.message['message']
            }),
            catchError((error) => {
                console.error(error);
                return of(null);
            }),
        )
    }

    // toastr(type: 'progress'): ActiveToast<any>
    // toastr(type: 'close', toastId?: number): void
    // toastr(type: 'close', toastRef?: ActiveToast<any>): void
    // toastr(type: 'open', config?: { message: string, url: string }): void
    /**
     * @param configOrRefOrId - config object for report, toast reference / toastId to close specific toast, empty to close first toast
     * @param toastType - 'progress' to show progress toast, 'close' to close, 'open'/leave empty for open toast
     * @returns reference to toast when type is 'progress', void for 'close' and 'open'
     */
    toastr(type: 'progress' | 'close' | 'open', configOrRefOrId?: { message: string, url: string } | ActiveToast<any> | number): void | ActiveToast<any> {
        if (type == 'progress') {
            const ref = this.log.progress('Procesare raport. Va rugam asteptati!', null, null, {
                closeButton: true, disableTimeOut: true, tapToDismiss: false
            });
            this.toasts.push(ref);
            return ref;
        } else if (type == 'close') {
            let toastRef: ActiveToast<any>;
            if (configOrRefOrId) {
                const idx = typeof configOrRefOrId == 'number'
                    ? this.toasts.findIndex(toast => toast.toastId == configOrRefOrId)
                    : this.toasts.indexOf(configOrRefOrId)
                if (idx > -1) { toastRef = <any>this.toasts.splice(idx, 1)[0] }
            } else {
                toastRef = this.toasts.shift();
            }
            if (toastRef) { toastRef.toastRef.close() }
        } else {
            const t = this.log.success(`<a href="javascript: void(0)">${configOrRefOrId['message']}</a>`, 'Deschide raport', null, {
                enableHtml: true, disableTimeOut: true, closeButton: true
            });
            t.onTap.subscribe(() => window.open(configOrRefOrId['url'], "_blank"));
        }
    }

    openOrShow(url: string) {
        if (!url) { return }
        let isOpen: boolean = false;
        if (this.isAutoOpen) { isOpen = this.open(url) }
        if (!isOpen) { this.showToast(url) }
    }
    showToast(url: string) {
        if (!environment.production) {
            console.log('ws response dev', url);
            url = (url || '').replace(environment['port'], environment['serverPort']);
        }
        const parts = url.split('/').pop().split('_');
        parts.pop();
        this.toastr('open', { message: parts.join('<br />'), url: url });
    }
    open(url: string) {
        const newWin = window.open(url, "_blank");
        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            const popup = 'Ferestrele pop-up sunt blocate pe această pagină !';
            const warn = 'Pentru a deschide automat rapoartele modificati setarile browserului.';
            this.log.warning(`${popup}<br />${warn}`, null, true, {
                enableHtml: true, timeOut: 5000, positionClass: 'toast-top-right'
            });
            return false;
        }
        return true;
    }
}